import React from 'react'
import * as classes from './Policy.module.scss'
import cn from 'classnames'
import Container from './../../ui/Container/Container'
import BreadCrumbs from './../../ui/BreadCrumbs/BreadCrumbs'

const Policy = ({ crumbs, data, lang }) => {
    return (
        <div className={cn(classes.Section, { [classes.LangHY]: lang === 'hy' })}>
            <Container>
                <BreadCrumbs list={crumbs} black />

                <div className={classes.Inner}>
                    <div className={classes.BlockStart}>
                        {data.blockStart.title && <h1 dangerouslySetInnerHTML={{ __html: data.blockStart.title }} />}

                        {data.blockStart.text && (
                            <div className={classes.Text} dangerouslySetInnerHTML={{ __html: data.blockStart.text }} />
                        )}
                    </div>

                    {data.items.nodes.length ? (
                        <div className={classes.Items}>
                            {data.items.nodes.map((item, index) => (
                                <div className={classes.Item} key={index}>
                                    <span className={classes.ItemNum}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</span>
                                    <h2 dangerouslySetInnerHTML={{ __html: item.title }} />
                                    <div className={classes.Text} dangerouslySetInnerHTML={{ __html: item.text }} />
                                </div>
                            ))}
                        </div>
                    ) : null}

                    <div className={classes.BlockEnd}>
                        {data.blockStart.title && <h2 dangerouslySetInnerHTML={{ __html: data.blockEnd.title }} />}

                        {data.blockStart.text && <div className={classes.Text} dangerouslySetInnerHTML={{ __html: data.blockEnd.text }} />}
                    </div>
                </div>
            </Container>
        </div>
    )
}

export default Policy
