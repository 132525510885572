// extracted by mini-css-extract-plugin
export var Section = "Policy-module--Section--LT7XA";
export var LangHY = "Policy-module--LangHY--gljie";
export var Item = "Policy-module--Item--O3+FB";
export var BlockEnd = "Policy-module--BlockEnd--S8RVU";
export var Inner = "Policy-module--Inner--ZIQOa";
export var BlockStart = "Policy-module--BlockStart--i162P";
export var Items = "Policy-module--Items--kDr8b";
export var Text = "Policy-module--Text--IF1Xx";
export var ItemNum = "Policy-module--ItemNum--3MfOo";
export var arrowLeft = "Policy-module--arrowLeft--Gj52G";
export var arrowRight = "Policy-module--arrowRight--+w7Eg";