// extracted by mini-css-extract-plugin
export var Header = "HeaderFixed-module--Header--A53RR";
export var Active = "HeaderFixed-module--Active--W8Nyk";
export var OpenMenu = "HeaderFixed-module--OpenMenu--R0lXJ";
export var Overlay = "HeaderFixed-module--Overlay--n1Z7H";
export var IsVisibility = "HeaderFixed-module--IsVisibility--afhCa";
export var Logo = "HeaderFixed-module--Logo--JQvny";
export var Menu = "HeaderFixed-module--Menu--rMmET";
export var LinkWrap = "HeaderFixed-module--LinkWrap--DrsXc";
export var IsParent = "HeaderFixed-module--IsParent--+nUfd";
export var DropDown = "HeaderFixed-module--DropDown--6ZIME";
export var ActiveParent = "HeaderFixed-module--ActiveParent--dVHqC";
export var Lang = "HeaderFixed-module--Lang--K0gll";
export var Burger = "HeaderFixed-module--Burger--hPjrB";
export var BurgerLine = "HeaderFixed-module--BurgerLine--04sgK";
export var arrowLeft = "HeaderFixed-module--arrowLeft--iKEmG";
export var arrowRight = "HeaderFixed-module--arrowRight--6NmnB";