import React from 'react'
import * as classes from './BreadCrumbs.module.scss'
import cn from 'classnames'
import { Link } from 'gatsby'

function BreadCrumbs({ list, black, className, lang }) {
    return (
        <div
            className={cn(classes.BreadCrumbs, className, {
                [classes.Black]: black,
                [classes.LangHY]: lang === 'hy'
            })}
        >
            {list.map((item, index) =>
                !item.current ? (
                    <Link to={`${item.href}`} key={index}>
                        {item.name}
                    </Link>
                ) : (
                    <span key={index}>{item.name}</span>
                )
            )}
        </div>
    )
}

export default BreadCrumbs
