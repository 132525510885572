import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from './../components/help/Layout/Layout'
import Seo from './../components/help/Seo'
import Header from './../components/ui/Header/Header'
import HeaderFixed from './../components/ui/HeaderFixed/HeaderFixed'
import Policy from './../components/pages/Policy/Policy'
import PageNavigation from './../components/ui/PageNavigation/PageNavigation'
import { getHrefToHome } from '../utils/getHrefToHome'

const PolicyPage = () => {
    const lang = 'hy'

    const hrefHome = getHrefToHome(lang)

    const data = useStaticQuery(graphql`
        {
            blockStart: datoCmsRenshinPrivacyPolicy(titleSystem: { eq: "Block start" }, locale: { eq: "hy" }) {
                text
                title
            }

            blockEnd: datoCmsRenshinPrivacyPolicy(titleSystem: { eq: "Block end" }, locale: { eq: "hy" }) {
                text
                title
            }

            items: allDatoCmsRenshinPrivacyPolicy(
                sort: { fields: [sortingBlockWithNumber], order: ASC }
                filter: { locale: { eq: "hy" }, titleSystem: { nin: ["Block start", "Block end"] } }
            ) {
                nodes {
                    title
                    text
                }
            }

            seo: datoCmsRenshinSeo(pageName: { eq: "Privacy policy" }, locale: { eq: "hy" }) {
                titleH1
                crumbsName

                footerLinks {
                    title
                    url
                }
                seo {
                    title
                    description
                }
            }

            main: datoCmsRenshinSeo(pageName: { eq: "Main" }, locale: { eq: "hy" }) {
                crumbsName
            }
        }
    `)

    const breadCrumbs = [
        { name: `${data.main?.crumbsName}`, href: `${hrefHome}`, current: false },
        { name: `${data.seo?.crumbsName}`, current: true }
    ]

    return (
        <Layout lang={lang}>
            <Seo title={data.seo?.seo?.title} description={data.seo?.seo?.description} />
            <Header lang={lang} />
            <HeaderFixed lang={lang} />
            <Policy crumbs={breadCrumbs} data={data} lang={lang} />
            <PageNavigation links={data?.seo?.footerLinks} lang={lang} />
        </Layout>
    )
}

export default PolicyPage
