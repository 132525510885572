import React from 'react'

function IconRight({ className }) {
    return (
        <svg className={className} width={22} height={17} viewBox='0 0 22 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M13.516 0.109707C13.5891 0.0365692 13.6988 0 13.8451 0C13.9548 0 14.0645 0.0365692 14.1376 0.109707L21.8537 7.86237C21.9269 7.93551 22 8.04521 22 8.15492C22 8.3012 21.9269 8.37434 21.8537 8.44747L14.1376 16.2001C14.0645 16.2733 13.9548 16.3098 13.8451 16.3098C13.6988 16.3098 13.5891 16.2733 13.516 16.2001L13.26 15.9076C13.1868 15.8344 13.1503 15.7613 13.1503 15.615C13.1503 15.5053 13.1868 15.3956 13.26 15.2859L19.8059 8.7766H0.43883C0.292553 8.7766 0.182846 8.74003 0.109707 8.66689C0.0365691 8.59375 0 8.48404 0 8.33777V7.97207C0 7.86237 0.0365691 7.75266 0.109707 7.67952C0.182846 7.60638 0.292553 7.53325 0.43883 7.53325H19.8059L13.26 1.02394C13.1868 0.950798 13.1503 0.841091 13.1503 0.694814C13.1503 0.585106 13.1868 0.475399 13.26 0.402261L13.516 0.109707Z'
                fill='white'
            />
        </svg>
    )
}

export default IconRight
