import { useState, useEffect } from 'react'

export function useShowFixedHeader() {
    const [showFixedHeader, setShowFixedHeader] = useState(false)

    let lastScrollTop = 0

    const handleScrol = () => {
        let ofset = window.pageYOffset

        if (ofset < lastScrollTop) {
            ofset > 900 ? setShowFixedHeader(true) : setShowFixedHeader(false)
        } else {
            setShowFixedHeader(false)
        }

        lastScrollTop = ofset
    }

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', handleScrol)

            return () => {
                window.removeEventListener('scroll', handleScrol)
            }
        }
    }, [])

    return { showFixedHeader }
}
