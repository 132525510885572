import React, { useEffect } from 'react'
import * as classes from './PageNavigation.module.scss'
import cn from 'classnames'
import Container from '../Container/Container'
import { Link } from 'gatsby'
import IconRight from '../../icons/IconRight'
import anime from 'animejs'

function PageNavigation({ links, transparet, miniTopPadding, lang }) {
    useEffect(() => {
        const blocks = document.querySelectorAll('.anime-scroll')

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.intersectionRatio > 0.25) {
                        if (entry.target.classList.contains('anime-link')) {
                            const delay = entry.target.getAttribute('data-delay')

                            anime({
                                targets: entry.target,
                                translateY: [60, 0],
                                opacity: [0, 1],
                                easing: 'easeOutExpo',
                                duration: 1400,
                                delay
                            })
                        }

                        if (entry.target.classList.contains('anime-line')) {
                            anime({
                                targets: entry.target,
                                opacity: [0, 1],
                                width: [0, '100%'],
                                easing: 'easeOutExpo',
                                duration: 1800
                            })
                        }

                        observer.unobserve(entry.target)
                    }
                })
            },
            {
                threshold: [0, 0.25, 1]
            }
        )

        blocks.forEach((block) => {
            observer.observe(block)
        })

        return () => observer.disconnect()
    }, [])

    return (
        <section className={cn(classes.Section, { [classes.Transparet]: transparet }, { [classes.MiniTopPadding]: miniTopPadding }, 'js-block-dark')}>
            {links && links.length ? (
                <Container>
                    <ul
                        className={classes.Items}
                        data-lang={lang}
                    >
                        {links.map((item, index) => (
                            <li
                                className={`${classes.Item} anime-scroll  anime-link`}
                                data-delay={400 * index}
                                key={index}
                                data-lang={lang}
                            >
                                <Link to={`${item.url}`}>
                                    {item.title}

                                    <div className={classes.IconRightWrap}>
                                        <IconRight />
                                    </div>

                                    <span className={`${classes.Line} anime-scroll  anime-line`} />
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Container>
            ) : null}
        </section>
    )
}

export default PageNavigation
