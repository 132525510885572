// extracted by mini-css-extract-plugin
export var Section = "PageNavigation-module--Section--AXnxG";
export var Transparet = "PageNavigation-module--Transparet--MOS+A";
export var MiniTopPadding = "PageNavigation-module--MiniTopPadding--nObUa";
export var Items = "PageNavigation-module--Items--cu+k6";
export var Item = "PageNavigation-module--Item--uhyjr";
export var Line = "PageNavigation-module--Line--akJAj";
export var IconRightWrap = "PageNavigation-module--IconRightWrap--1fFER";
export var arrowLeft = "PageNavigation-module--arrowLeft--GGT7y";
export var arrowRight = "PageNavigation-module--arrowRight--Kx4En";
export var circleAnimation = "PageNavigation-module--circleAnimation--6xKQz";